import React from 'react';
import Page from '../components/page';

const Products = () => {
  const productArray = [
    {name: "Vitamin", category: "beanbag"},
    {name: "Gula batu", category: "furniture"},
  ]
  return (<>
  <Page>
     <div className="products-page page-content">
          <div className="page-title">PRODUCTS</div>
          <div className="products-content">
            
          </div>
        </div>
  </Page>
  </>);
}
 
export default Products;